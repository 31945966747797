import { Breadcrumb, Button, Input, List, message, Modal, Popconfirm, Select, Spin, Tabs, TabsProps, Upload, UploadFile, UploadProps } from "antd";
import PanelPageTitle from "../../../components/PanelPageTitle";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Quill'in varsayılan stil dosyası
import { CheckOutlined, CloseOutlined, DeleteOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { faFileMedical, faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import HeadWordService from "../../../services/HeadWordService";
import Loading from "../../../components/Loading";
import { IAddMeaning } from "../../../models/meaning/IAddMeaning";
import MeaningService from "../../../services/MeaningService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ISubTematic } from "../../../models/tematic/ISubTematic";
import { IMeaningFile } from "../../../models/meaning/IMeaningFile";
import 'react-quill/dist/quill.snow.css'; 
import { ISearchHeadWordRequest } from "../../../models/headWord/ISearchHeadWordRequest";

const AddMeaning = () =>{
    const quillRef = useRef<any>(null);

    const { headWordId } = useParams();

    const [isLoadingGetHeadWord, setIsLoadingGetHeadWord]= useState(false)
    const [headWordName, setHeadWordName]= useState("") 
    const [headWordHomophoneCode, setHeadWordHomophoneCode]= useState("") 
    const _meaningService = MeaningService();
    const _headWordService = HeadWordService();
    const [isCapsLockOn, setIsCapsLockOn] = useState(false);
    const navigate = useNavigate();
    const [isLoadingCustomLink, setIsLoadingCustomLink]= useState(false)
    const [isModalCustomLink, setIsModalCustomLink] = useState(false);
    const [selectedCustomLink, setSelectedCustomLink] = useState("");
    const [customLinkOptions, setCustomLinkOptions] = useState([]);


    const [order, setOrder] = useState("");
    const [definition, setDefinition] = useState("");
    const [wordType, setWordType] = useState("-");
    const [languageLevel, setLanguageLevel] = useState("-");
    const [english, setEnglish] = useState("");
    const [russian, setRussian] = useState("");
    const [croatian, setCroatian] = useState("");
    const [arabic, setArabic] = useState("");
    const [exampleSentence, setExampleSentence] = useState("");
    const [addedExampleSentence, setAddedExampleSentence] = useState<string[]>([]);
    const [fields, setFields] = useState<IMeaningFile[]>([]);



    const [ userData, setUserData ] = useState(Object);

  
    useEffect(() => {

    const storedData = localStorage.getItem("userData");
    const parsedData = storedData ? JSON.parse(storedData) : null;
    setUserData(parsedData)

    if (quillRef.current) {
        const quill = quillRef.current.getEditor();

        // Toolbar'a özel buton ekleyelim
        const customButton = document.createElement('button');
        customButton.classList.add('ql-customlink');
        
        // Font Awesome ikonu ekliyoruz
        customButton.innerHTML = "Url"; // Link ikonu ve metin

        // Buton tıklandığında handleCustomButtonClick fonksiyonunu çağır
        customButton.addEventListener('click', handleLinkModal);

        // Butonu toolbar container'ının sonuna ekleyelim
        const toolbar = quill.getModule('toolbar');
        const toolbarContainer = toolbar.container;
        
        // Son öğeyi alalım
        const lastChild = toolbarContainer.lastElementChild;

        // Butonu son öğenin sonrasına ekleyelim
        if (lastChild) {
        toolbarContainer.insertBefore(customButton, lastChild.nextSibling);
        } else {
        toolbarContainer.appendChild(customButton); // Eğer toolbar boşsa
        }
    }

    getByIdHeadWord()        

    }, []);


    const handleLinkModal = () => {

        
        if (quillRef.current) {
            const quill = quillRef.current.getEditor();
            const range = quill.getSelection();
            
            if(range == null )
            {
                message.error("Lütfen bir yer seçiniz !")
            }
            if(range != null && range.length == 0)
            {
                message.error("Lütfen bir yer seçiniz !")
            }
            
            if (range != null && range.length != 0) {
                setIsModalCustomLink(true);
            }
        }
    };
    const handleLinkCancel = () =>{

        setIsModalCustomLink(false);
        setSelectedCustomLink("");
    }
    const handleLinkOk = () =>{

        if (selectedCustomLink == "") {
            return message.error("Lütfen madde başı seçiniz !")
        }

        const quill = quillRef.current.getEditor();
        quill.format('link', selectedCustomLink);
        setIsModalCustomLink(false);
    
    }
    const handleLinkSearch = async (word:string) =>{
        if (word.length > 2) {

            try {
                setIsLoadingCustomLink(true);
                const data: ISearchHeadWordRequest = {
                    headWordName : word
                }
                var response = await _headWordService.searchHeadWord(data);
                console.log('response searchHeadWord: ', response);
    
                if (response.statusCode == 200 ) {
    
                    if (response.data != null) {
                        const formattedOptions = response.data.map((item:any, index:any) => ({
                            value: item, 
                            label: item,
                        }));
                        setCustomLinkOptions(formattedOptions);
                    }
                }
                
                response.errors.forEach((i:any) => {                
                    message.error(i)
                });
            } catch (err) {
                console.log('err: ', err);
            }
            finally{
                setIsLoadingCustomLink(false)
            }
            
        }        
    }


    const addExampleSentence = (sentence: string) => {
        const trimmedSentence = sentence.trim();
    
        if (trimmedSentence == "") {
            return message.error("Boş geçilemez !");
        }
    
        setAddedExampleSentence((prev) => {
            // Eğer sentence zaten listede varsa ekleme yapma
            if (prev.includes(trimmedSentence)) {
                message.warning("Bu cümle zaten eklendi!");
                return prev; // Mevcut listeyi döndür
            }
            
            // Yeni kelimeyi ekle
            return [...prev, trimmedSentence];
        });
    
        setExampleSentence("");
    };

    const deleteExampleSentence = (sentence: string) => {
        setAddedExampleSentence(e => e.filter(x => x !== sentence));
        message.info('Örnek cümle silindi!');
    };

    const getUploadProps = (index: number): UploadProps => ({
        onChange: ({ fileList }) => handleFileChange(index, fileList),
        onRemove: () => handleFileChange(index, []),
        beforeUpload: () => false, // Yüklemeyi durdur
        fileList: fields[index]?.image ? [fields[index].image as UploadFile] : [], // Mevcut dosyayı al
    });
    
    // İngilizce
    const englishLetters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

    // Rusça
    const russianLetters = ["А", "Б", "В", "Г", "Д", "Е", "Ё", "Ж", "З", "И", "Й", "К", "Л", "М", "Н", "О", "П", "Р", "С", "Т", "У", "Ф", "Х", "Ц", "Ч", "Ш", "Щ", "Ъ", "Ы", "Ь", "Э", "Ю", "Я"];

    // Hırvatça
    const croatianLetters = ["A", "B", "C", "Č", "Ć", "D", "Đ", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "R", "S", "Š", "T", "U", "V", "Z", "Ž"];

    // Arapça
    const arabicLetters = ["ا", "ب", "ت", "ث", "ج", "ح", "خ", "د", "ذ", "ر", "ز", "س", "ش", "ص", "ض", "ط", "ظ", "ع", "غ", "ف", "ق", "ك", "ل", "م", "ن", "ه", "و", "ي"];

    const handleClickEnglish = (letter:any) => {
        const finalLetter = isCapsLockOn ? letter.toUpperCase() : letter.toLowerCase();
        setEnglish(english + finalLetter);
    };

    const handleClickRussian = (letter:any) => {
        console.log('letter: ', letter);
        const finalLetter = isCapsLockOn ? letter.toUpperCase() : letter.toLowerCase();
        console.log('finalLetter: ', finalLetter);
        setRussian(russian + finalLetter);
    };
    
    const handleClickCroatian = (letter:any) => {
        const finalLetter = isCapsLockOn ? letter.toUpperCase() : letter.toLowerCase();
        setCroatian(croatian + finalLetter);
    };
    
    const handleClickArabic = (letter:any) => {
        const finalLetter = isCapsLockOn ? letter.toUpperCase() : letter.toLowerCase();
        setArabic(arabic + finalLetter);
    };
    
    const toggleCapsLock = () => {
    setIsCapsLockOn(!isCapsLockOn);
    };

    

    const getByIdHeadWord = async() =>{
        try {
            setIsLoadingGetHeadWord(true);
            const data = {
                headWordId : headWordId!
            }
            var response = await _headWordService.getByIdHeadWord(data);
            console.log('response: ', response);

            if (response.statusCode == 200 ) {

                if (response.data != null) {
                    setHeadWordName(response.data.name)
                    setHeadWordHomophoneCode(response.data.homophoneCode) 
                }
            }
            
            response.errors.forEach((i:any) => {                
                message.error(i)
            });
        } catch (err) {
            console.log('err: ', err);
        }
        finally{
            setIsLoadingGetHeadWord(false);
        }
    }

    const items: TabsProps['items'] = [
        {
          key: '1',
          label: 'İngilizce',
          children: 
          <div>
                <div className="flex flex-wrap justify-center gap-[5px] sm:pr-0 sm:pl-0 pr-40 pl-40">
                    {englishLetters.map((englishLetter) => (
                        <Button
                            key={englishLetter}
                            onClick={() => handleClickEnglish(englishLetter)}
                            className="p-[20px] w-6"
                        >
                            {isCapsLockOn ? englishLetter.toUpperCase() : englishLetter.toLowerCase()}
                        </Button>

                    ))}
                        <Button
                        onClick={toggleCapsLock}
                        className="p-[20px] w-[135px]"
                        >
                        ⬆️
                        </Button>
                </div>
                <Input
                    type="text"
                    value={english}
                    placeholder="İngizlice"
                    onChange={e=>setEnglish(e.target.value)}
                    style={{
                    marginTop: "20px",
                    width: "100%",
                    fontSize: "18px",
                    }}
                />
            </div>
          ,
        },
        {
          key: '2',
          label: 'Rusça',
          children: 
            <div>
                <div className="flex flex-wrap justify-center gap-[5px] sm:pr-0 sm:pl-0 pr-40 pl-40">
                    {russianLetters.map((russianLetter) => (
                        <Button
                            key={russianLetter}
                            onClick={() => handleClickRussian(russianLetter)}
                            className="p-[20px] w-6"
                        >
                            {isCapsLockOn ? russianLetter.toUpperCase() : russianLetter.toLowerCase()}
                        </Button>

                    ))}
                        <Button
                        onClick={toggleCapsLock}
                        className="p-[20px] w-[135px]"
                        >
                        ⬆️
                        </Button>
                </div>
                <Input
                    type="text"
                    value={russian}
                    placeholder="Rusça"
                    onChange={e=>setRussian(e.target.value)}
                    style={{
                    marginTop: "20px",
                    width: "100%",
                    fontSize: "18px",
                    }}
                />
            </div>
          ,
        },
        {
        key: '3',
        label: 'Hırvatça',
        children: 
        <div>
                <div className="flex flex-wrap justify-center gap-[5px] sm:pr-0 sm:pl-0 pr-40 pl-40" >
                    {croatianLetters.map((croatianLetter) => (
                        <Button
                            key={croatianLetter}
                            onClick={() => handleClickCroatian(croatianLetter)}
                            className="p-[20px] w-6"
                        >
                            {isCapsLockOn ? croatianLetter.toUpperCase() : croatianLetter.toLowerCase()}
                        </Button>

                    ))}
                        <Button
                        onClick={toggleCapsLock}
                        className="p-[20px] w-[135px]"
                        >
                        ⬆️
                        </Button>
                </div>
                <Input
                    type="text"
                    value={croatian}
                    placeholder="Hırvatça"
                    onChange={e=>setCroatian(e.target.value)}
                    style={{
                    marginTop: "20px",
                    width: "100%",
                    fontSize: "18px",
                    }}
                />
        </div>,
        },
        {
        key: '4',
        label: 'Arapça',
        children:
        <div>
                <div className="flex flex-wrap justify-center gap-[5px] sm:pr-0 sm:pl-0 pr-40 pl-40" >
                    {arabicLetters.map((arabicLetter) => (
                        <Button
                            key={arabicLetter}
                            onClick={() => handleClickArabic(arabicLetter)}
                            className="p-[20px] w-6"
                        >
                            {isCapsLockOn ? arabicLetter.toUpperCase() : arabicLetter.toLowerCase()}
                        </Button>

                    ))}
                        <Button
                        onClick={toggleCapsLock}
                        className="p-[20px] w-[135px]"
                        >
                        ⬆️
                        </Button>
                </div>
                <Input
                    type="text"
                    value={arabic}
                    placeholder="Arapça"
                    onChange={e=>setArabic(e.target.value)}
                    style={{
                    marginTop: "20px",
                    width: "100%",
                    fontSize: "18px",
                    }}
                />
        </div>,
        },
      ];


    const handleOk = async () =>{
        try {

            if(validatorMeaning() == false)
            {
                return;
            }


            var data:IAddMeaning ={
                
                    headWordId : headWordId!,
                    order : order,
                    wordType : wordType,
                    definition : definition,
                    languageLevel : languageLevel,
                    languageEnglish : english,
                    languageRussian : russian,
                    languageCroatian : croatian,
                    languageArabic : arabic,
                    exampleSentence : addedExampleSentence,
                    file : fields,
                }   
            console.log('data: ', data);
            
            var response = await _meaningService.addMeaning(data);
            console.log('response: ', response);
            if (response.statusCode == 200) {
                message.success("Anlam eklendi")
                if (userData.role === "Manager") {
                    navigate("/manager/meaning/"+headWordId);
                } else if (userData.role === "Employee") {
                    navigate("/employee/meaning/"+headWordId);
                }
                
            }
            response.errors.forEach((i:any) => {                
                message.error(i)
            });  
            
        } catch (err) {
            console.log("AddMeaning",err)
        }


    }

    const validatorMeaning = () =>{
        if (order=="") {
            message.error("Anlam sırası boş geçilemez !")
            return false;
        }
        if (wordType=="-") {
            message.error("Sözcük türü boş geçilemez !")
            return false;
        }
        if (definition=="" || definition == "<p><br></p>") {
            message.error("Sözcüğün anlamı boş geçilemez !")
            return false;
        }


        const hasIncompleteFields = fields.some(
            (field) => !field.description.trim() || !field.image
        );
    
        if (hasIncompleteFields) {
            message.error("Lütfen tüm alanları doldurun ve bir görsel yükleyin!")
            return false;
        }

        return true;
    }

    const addFields = () => {
        setFields([...fields, { description: "", image: null }]);

        console.log(fields);
        
      };

    const handleInputChange = (index: number, field: keyof IMeaningFile, value: string) => {
        const updatedFields = [...fields];
        updatedFields[index].description = value;
        setFields(updatedFields);
      };


    const handleFileChange = (index: number, fileList: UploadFile[]) => {
        const updatedFields = [...fields];
        updatedFields[index].image = fileList[0] || null; // Sadece ilk dosyayı kaydet
        setFields(updatedFields);
    };
      const deleteField = (index:any) =>{
        setFields((prevFields) => {
            // Diziyi filtreleyerek belirtilen index'i kaldır
            return prevFields.filter((_, i) => i !== index);
        });
        
    }

    
    return(
        <>
            <PanelPageTitle icon={faFileMedical} bg="bg-[#3498DB]" title="Anlam Ekle"/>

            <Breadcrumb
                    className="mt-4"
                    items={[
                        {
                            title: <Link to={userData.role === "Manager" ? "/manager/head-word" : "/employee/head-word"}>Madde Başı Listesi</Link>,
                        },
                        {
                            title: <Link to={userData.role === "Manager" ? "/manager/meaning/"+headWordId : "/employee/meaning/"+headWordId} >Anlam Listesi</Link>,
                        },
                        {
                            title: 'Anlam Ekle',
                        },
                    ]}
                />

            {
                isLoadingGetHeadWord == true ?
                <Loading /> :
                <></>
            }

            <div className="mt-5 aaa">

                <div className="text-center mb-3 text-[25px] bg-yellow-300 opacity-50 p-3 rounded-lg">
                    <span className="font-bold"> {headWordName} - {headWordHomophoneCode} </span>
                </div>

                <div className="bg-[#FDFDF8] p-7 rounded-lg mb-3">
                    <span className="text-[20px] font-bold">Anlam Sırası * : </span>
                    <Input className='mt-3 h-[40px] text-[20px]' type="number" value={order} onChange={e=>setOrder(e.target.value)} placeholder="0"  />
                </div>

                <div className="bg-[#FDFDF8] p-7 rounded-lg mb-3">
                    <span className="text-[20px] font-bold">Sözcük Türü * : </span>
                    <Select
                    placeholder="Sözcük Türünü Seçiniz"
                    className='w-full mt-3 h-[40px] '
                    value={wordType} onChange={e=>setWordType(e)}
                    options={[
                        { value: '-', label: 'Seçiniz' },
                        { value: 'İsim', label: 'İsim' },
                        { value: 'Sıfat', label: 'Sıfat' },
                        { value: 'Fiil', label: 'Fiil' },
                        { value: 'Belirgeç', label: 'Belirgeç' },
                        { value: 'Bağlaç', label: 'Bağlaç' },
                    ]}
                    />
                </div>
            
                <div className="bg-[#FDFDF8] p-7 rounded-lg mb-3">
                    <span className="text-[20px] font-bold">Sözcüğün Anlamı * : </span>
                    <ReactQuill
                    value={definition}
                    onChange={e=>setDefinition(e)}
                    className="mt-3 h-[200px] mb-[40px] !rounded-lg"
                    placeholder="Metninizi buraya yazın..."
                    modules={{
                    toolbar: [
                        ["bold", "italic", "underline"], // Kalın, italik, altı çizili ve üstü çizili
                        ["link"]
                    ],
                    }}
                    formats={[
                        "bold",
                        "italic",
                        "underline",
                        "link",
                        ]}
                    />                
                </div>

                <div className="bg-[#FDFDF8] p-7 rounded-lg mb-3">
                    <span className="text-[20px] font-bold">Dil Seviyesi  : </span>
                    <Select
                    placeholder="Dil Seviyesini Seçiniz"
                    className='w-full mt-3 h-[40px]'
                    value={languageLevel}
                    onChange={e=>setLanguageLevel(e)}
                    options={[
                        { value: '-', label: 'Seçiniz' },
                        { value: 'A1', label: 'A1' },
                        { value: 'A2', label: 'A2' },
                    ]}
                    />
                </div>

                <div className="bg-[#FDFDF8] p-7 rounded-lg mb-3">
                    <span className="text-[20px] font-bold">Yabancı Dil : </span>
                    <Tabs centered defaultActiveKey="1" items={items} />
                    
                </div>


                <div className="bg-[#FDFDF8] p-7 rounded-lg mb-3">
                        <span className="text-[20px] font-bold">Örnek Görseller :</span>
                        <br />

                        <Button onClick={addFields} type="primary" 
                        className="w-full mt-5 bg-gray-500 hover:!bg-gray-400">
                                <FontAwesomeIcon icon={faPlus} size="1x"  />
                                Ekle
                        </Button>

                        <div>
                        {fields.map((field, index) => (
                        <div key={index} 
                            className="flex gap-3 mt-3">

                            <Input className='h-[40px] w-full' placeholder="Görsel açıklama" 
                                value={field.description} 
                                onChange={(e) => handleInputChange(index, "description", e.target.value)} />

                          
                            <Upload {...getUploadProps(index)} maxCount={1} accept="image/*">
                                <Button className="h-[40px]" icon={<UploadOutlined />}>Görsel Ekleyin</Button>
                            </Upload>

                            <Popconfirm
                                title="Silmek istiyor musunuz ?"
                                onConfirm={e=>{deleteField(index)}}
                                okText="Evet"
                                cancelText="Hayır"
                            >
                            
                                <Button type="primary" 
                                className="h-[40px] bg-red-500 hover:!bg-red-400">
                                    <FontAwesomeIcon icon={faTrashAlt} size="1x"  />
                                </Button>
                            </Popconfirm>

                            
                        </div>
                        ))}
                        </div>

                </div>

                <div className="bg-[#FDFDF8] p-7 rounded-lg mb-3">
                    <span className="text-[20px] font-bold">Örnek Cümleler :</span>
                    <div className="flex gap-2 items-center">

                        <ReactQuill
                        ref={quillRef}
                        className="mt-3 w-full !rounded-lg"
                        placeholder="Metninizi buraya yazın..."
                        value={exampleSentence}
                            onChange={e=>setExampleSentence(e)}
                        modules={{
                        toolbar: [
                            ["bold", "italic", "underline"], // Kalın, italik, altı çizili ve üstü çizili
                        ],
                        }}
                        formats={[
                            "bold",
                            "italic",
                            "underline",
                            "link",
                            "customlink"
                            ]}
                        />  

                        <Button type="primary"  
                        onClick={e=>addExampleSentence(exampleSentence)} 
                        className="bg-[#3498DB] hover:!bg-opacity-70 mt-14 h-[61px] !w-[40px]" icon={<PlusOutlined />} />


                        <Modal title="Madde Başı Seç" 
                            okText="Kaydet"
                            cancelText="İptal" 
                            maskClosable={false} 
                            okButtonProps={{ className: 'bg-green-500 text-white hover:!bg-green-300' }}  
                            open={isModalCustomLink} onOk={handleLinkOk} onCancel={handleLinkCancel}>

                            
                            <div>
                            <Select
                                showSearch
                                placeholder="Madde başı ara..."
                                optionFilterProp="label"
                                onChange={e=>setSelectedCustomLink(e)}
                                onSearch={e=>handleLinkSearch(e)}
                                className="w-full mt-5 mb-5"
                                notFoundContent={
                                    isLoadingCustomLink == true ? <Spin size="small" /> : 'Madde başı bulunamadı !'
                                }
                                options={customLinkOptions}
                            />
                            </div>

        
                        </Modal>

                    </div>


                    <div className="mt-5">
                            {
                                addedExampleSentence.length != 0 ?
                                (
                                    <List
                                
                                    bordered
                                    dataSource={addedExampleSentence}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <div className="flex justify-between items-center  w-full">
    
                                                <div dangerouslySetInnerHTML={{ __html: item }} />
    
    
                                                <Popconfirm
                                                    title="Örnek cümleyi silmek istiyor musunuz ?"                                                    
                                                    okText="Sil"
                                                    cancelText="İptal"
                                                    onConfirm={() => deleteExampleSentence(item)}
                                                >
                                                    <Button
                                                    type="primary"
                                                    className="bg-red-700 hover:!bg-red-500" 
                                                    icon={<DeleteOutlined />} 
                                                    >
                                                    </Button>
                                                </Popconfirm>
    
                                                
                                            </div>
    
                                        </List.Item>
                                    )}
                                    locale={{
                                        emptyText: <span className="text-red-400 font-bold">Boş !</span>
                                    }}
                                    />
    
                                ):
                                (
                                    <>
                                    </>
                                )
                            }
    
                            
    
                        </div>

                    
                </div>

                
                <div className="p-7 mb-3 flex justify-end  gap-3">
                    <Button type="primary" 
                    
                    onClick={(e) => {
                        if (userData.role === "Manager") {
                            navigate("/manager/meaning/"+headWordId)
                        } else if (userData.role === "Employee") {
                            navigate("/employee/meaning/"+headWordId)
                        }
                    }}
                    className="bg-red-500 hover:!bg-red-400 h-[40px]" icon={<CloseOutlined />} >
                        İptal
                    </Button>
                    <Button type="primary"  onClick={handleOk}  className="bg-green-500 hover:!bg-green-400 h-[40px]" icon={<CheckOutlined />} >
                        Kaydet
                    </Button>
                </div>

            </div>

        </>
    );
}

export default AddMeaning;