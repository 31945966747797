import { faAddressCard, faClose, faLock } from "@fortawesome/free-solid-svg-icons";
import PanelPageTitle from "../../../components/PanelPageTitle";
import { Button, Input, message, Select } from "antd";
import { useEffect, useState } from "react";
import UserService from "../../../services/UserService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../../../components/Loading";
import ChangePassword from "./components/ChangePassword";

const MyInformation = () =>{

    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [username, setUsername] = useState("");
    const [mail, setMail] = useState("");
    const [isDisable, setIsDisable] = useState(true);
    const [gender, setGender] = useState("Cinsiyeti Seçiniz");
    const _userService = UserService();
    const [isLoadingUser, setIsLoadingUser]= useState(false)
    const [isLoadingUpdateUser, setIsLoadingUpdateUser]= useState(false)


    useEffect(()=>{
        getByIdUser();
    },[])

    const getByIdUser = async ()=>{
        try { 

        
            setIsLoadingUser(true);
            var response = await _userService.getByMyInformation();
            
            if (response.statusCode == 200 ) {
               
                console.log('response: ', response);
                setId(response.data.userId)
                setName(response.data.name)
                setSurname(response.data.surname)
                setUsername(response.data.username)    
                setMail(response.data.mail)    
                setGender(response.data.gender)
            
            }
            
            response.errors.forEach((i:any) => {                
                message.error(i)
            });
        } catch (err) {
            console.log("getByIdUser :", err);
        }finally{
            setIsLoadingUser(false);
        }
        
    }

    const validatorUser = (data:any)=>{

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        const usernameRegex = /^(?!.*[_.]{2})[a-zA-Z0-9._]{3,20}$/;

        if (data.name == "") {
            message.error("Ad boş geçilemez !")
            return false;
        }
        if (data.surname == "") {
            message.error("Soyad boş geçilemez !")
            return false;

        }
        if (data.username == "") {
            message.error("Kullanıcı adı boş geçilemez !")
            return false;

        }
        if (data.mail == "") {
            message.error("Mail boş geçilemez !")
            return false;
        }
        if (!emailRegex.test(data.mail)) {
            message.error("Geçersiz e-posta formatı !")
            return false;
        }
         // Türkçe karakter kontrolü
        const hasTurkishChars = /[ğüşıöçĞÜŞİÖÇ]/.test(data.username);
        if (hasTurkishChars) {
            return "Kullanıcı adı Türkçe karakter içeremez.";
        }
        if (!usernameRegex.test(data.username)) {
            return "Geçerli bir kullanıcı adı girin. (3-20 karakter, boşluk yok, Türkçe karakter yok)";
         }
        if (data.gender == "Cinsiyeti Seçiniz") {
            message.error("Cinsiyet boş geçilemez !")
            return false;
        }
       

        return true;
        
    }

    const handleUpdate =async () =>{

        if (isDisable == true) {
            setIsDisable(false)
        }
        else{
            try{

                var data ={
                    userId:id,
                    name: name,
                    surname:surname,
                    username:username,
                    mail:mail,
                    gender:gender,

                }
                console.log('data: ', data);
                var result =validatorUser(data)
                if (result == false) {
                    return
                }

                setIsLoadingUpdateUser(true)
                var response = await _userService.updateMyInformation(data);
                console.log('response: ', response);
                if (response.statusCode == 200) {
                    message.success("Kullanıcı güncellendi")
                    getByIdUser();
                    setIsDisable(true);
                }
                response.errors.forEach((i:any) => {                
                    message.error(i)
                });
                
            } catch (err) {
                console.log("handleUpdate",err)
            }
            finally{
                setIsLoadingUpdateUser(false)

            }
            
        }


    }

    
    return (
        <>
            <div>
                <PanelPageTitle icon={faAddressCard} title="Bilgilerim"/>

                <ChangePassword userId={id}/>

                {
                    isLoadingUser == true ? 
                    <Loading /> : <></>
                }

                <div className="mt-5 mb-4 border  rounded-xl p-10">
                    <div className="mb-4">
                        <span className="font-bold"></span>
                        <Input className='mt-3 h-[40px]' disabled={isDisable} value={name} onChange={e=>setName(e.target.value)}  placeholder="Adınızı yazınız"  />
                    </div>

                    <div className="mb-4">
                        <span className="font-bold"></span>
                        <Input className='mt-3 h-[40px]' disabled={isDisable} value={surname} onChange={e=>setSurname(e.target.value)}  placeholder="Soyadınızı yazınız"  />
                    </div>

                    <div className="mb-4">
                        <span className="font-bold"></span>
                        <Input className='mt-3 h-[40px]' disabled={isDisable} value={username} onChange={e=>setUsername(e.target.value)}  placeholder="Kullanıcı adını yazınız"  />
                    </div>

                    <div className="mb-4">
                        <span className="font-bold"></span>
                        <Input className='mt-3 h-[40px]' disabled value={mail} onChange={e=>setMail(e.target.value)}  placeholder="Mail adresinizi yazınız"  />
                    </div>
                    
                    <div className="mb-4">
                        <span className="font-bold"></span>
                        <Select
                            placeholder="Cinsiyeti Seçiniz"
                            value={gender}
                            disabled={isDisable} 
                            onChange={e => setGender(e)}
                            className='w-full mt-3 h-[40px]'
                            options={[
                                { value: 'E', label: 'Erkek' },
                                { value: 'K', label: 'Kız' },
                            ]}
                            />
                    </div>

                    <div className=" flex justify-end">
                        {
                            isDisable == false ? 
                            <Button type="primary" onClick={e=>setIsDisable(true)}  className="bg-red-500 ml-1 mr-1 p-5 hover:!bg-red-400" icon={<FontAwesomeIcon icon={faClose} size="2x"  />}  >İptal </Button> : <></>
                        }
                        <Button type="primary" onClick={handleUpdate}  className="bg-yellow-500 ml-1 mr-1 p-5 hover:!bg-yellow-400" icon={<FontAwesomeIcon icon={faAddressCard} size="2x"  />}  >Güncelle </Button>

                    </div>
                    

                </div>
                
            </div>
        </>
    );
}

export default MyInformation;