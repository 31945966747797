import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Input, message, Modal, Switch } from "antd";
import { useEffect, useState } from "react";
import Loading from "../../../../components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import UserService from "../../../../services/UserService";

const ChangePassword = (props:any) =>{
    
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordRepeat, setNewPasswordRepeat] = useState("");
    const _userService = UserService();
    const [isLoadingChangePassword, setIsLoadingChangePassword]= useState(false)



    const showModal = () => {
        setIsModalOpen(true);
    };

    const validatorPassword = (data:any) => {

        const { oldPassword, newPassword, newPasswordRepeat } = data;
            
        // Genel boşluk kontrolü
        if (!oldPassword) {
        message.error("Eski şifre boş geçilemez!");
        return false;
        }

        if (!newPassword) {
        message.error("Yeni şifre boş geçilemez!");
        return false;
        }
    
        if (!newPasswordRepeat) {
        message.error("Yeni şifre tekrar boş geçilemez!");
        return false;
        }
    
        // Şifre eşleşmesi kontrolü
        if (newPassword !== newPasswordRepeat) {
        message.error("Yeni şifreler birbiriyle uyuşmuyor!");
        return false;
        }

        // Şifre kuralları için regex
        const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&\-])[A-Za-z\d@$!%*?&\-]{9,}$/;

    
        if (!passwordRegex.test(newPassword)) {
        message.error(
            "Şifre en az 9 karakter olmalı, bir büyük harf, bir küçük harf, bir rakam ve bir özel karakter içermelidir."
        );
        return false;
        }
    
        return true;
    };
    

    const handleOk = async () => {
        try {

            var data ={
                userId:props.userId ,
                oldPassword : oldPassword,
                newPassword : newPassword,
                newPasswordRepeat : newPasswordRepeat,
                
            }
            var result =validatorPassword(data)
            if (result == false) {
                return
            }

            setIsLoadingChangePassword(true)
            var response = await _userService.changePassword(data);
            console.log('response: ', response);
            if (response.statusCode == 200) {
                message.success("Şifre güncellendi")
                setIsModalOpen(false);

                // callback'i çağır
                
                props.onDepartmentUpdated();
                
                
            }
            response.errors.forEach((i:any) => {                
                message.error(i)
            });
            
        } catch (err) {
            console.log("update user",err)
        }
        finally{
            setIsLoadingChangePassword(false)
            setOldPassword("");
            setNewPassword("")
            setNewPasswordRepeat("")
        }
    };

    const handleCancel = () =>{
        setIsModalOpen(false)
        setOldPassword("");
        setNewPassword("")
        setNewPasswordRepeat("")
    }


    return(
        <>


            <Button type="primary" onClick={showModal}  className="mt-5 bg-gray-500 ml-1 mr-1 p-5 hover:!bg-gray-400" 
            icon={<FontAwesomeIcon icon={faLock} size="2x"  />}  >Şifre Değiştir </Button>
            <Modal title= "Şifre Değiştir"
                okText="Değiştir"
                cancelText="İptal" 
                maskClosable={false} 
                okButtonProps={{ className: 'bg-green-500 text-white hover:!bg-green-300' }}  
                open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>

                    {
                        isLoadingChangePassword == true ?
                        <Loading/> : <></>
                    }
                    <>

                    <div className='mt-5'>
                        <Input.Password className='h-[40px]' type="password" placeholder="Eski Şifreniz" value={oldPassword} onChange={e=>{setOldPassword(e.target.value)}} />
                    </div>

                    <div className='mt-5'>
                        <Input.Password className='h-[40px]' type="password" placeholder="Yeni Şifre" value={newPassword} onChange={e=>{setNewPassword(e.target.value)}} />
                    </div>

                    <div className='mt-5'>
                        <Input.Password className='h-[40px]' type="password" placeholder="Yeni Şifre Tekrar" value={newPasswordRepeat} onChange={e=>{setNewPasswordRepeat(e.target.value)}} />
                    </div>

                    </>

                

            </Modal>
        
        </>
    );
}

export default ChangePassword;
