import { Card, Col, message, Row } from "antd";
import { useEffect, useState } from "react";
import DashboardService from "../services/DashboardService";
import Loading from "./Loading";

const GeneralStatistic = () =>{
    const [dataSource, setDataSource] = useState(Object);
    const _dashboardService = DashboardService();
    const [isLoadingGeneralStatistic, setIsLoadingGeneralStatistic]= useState(false)

    useEffect(() =>{
        getGeneralStatistic();        
    }, []) 


    const getGeneralStatistic = async ()=>{
        try { 

            setIsLoadingGeneralStatistic(true);
            var response = await _dashboardService.getGeneralStatistic();
            
            if (response.statusCode == 200 ) {
                //console.log('response: ', response);
                //console.log('response: ', dataSource);
            
                setDataSource(response.data);
            }
            
            response.errors.forEach((i:any) => {                
                message.error(i)
            });
        } catch (err) {
            console.log("getGeneralStatistic :", err);
        }finally{
            setIsLoadingGeneralStatistic(false);

        }
        
    }

    return(
        <>

            {
                isLoadingGeneralStatistic == true ?
                <Loading />:<></>
            }

            <Row gutter={16} className="">

                <Col span={8}>
                    <Card title="Toplam Madde Başı"  className="mt-5 text-center bg-green-200 shadow-lg" >
                        <div className="font-bold text-[40px]">{dataSource.totalHeadWord}</div>
                    </Card>
                </Col>
                
                <Col span={8}>
                    <Card title="Anlamı Olmayan Madde Başı"  className="mt-5 text-center bg-blue-200 shadow-lg" >
                        <div className="font-bold text-[40px]">{dataSource.meaninglessHeaderCount}</div>
                    </Card>
                </Col>

                <Col span={8}>
                    <Card title="Anlamı Olan Madde Başı"  className="mt-5 text-center bg-yellow-100 shadow-lg" >
                        <div className="font-bold text-[40px]">{dataSource.headingWithMeaningCount}</div>
                    </Card>
                </Col>

                <Col span={8}>
                    <Card title="Yayında Olan Kelime" className="mt-5 text-center bg-orange-200 shadow-lg" >
                        <div className="font-bold text-[40px]">{dataSource.publishedWordCount}</div>
                    </Card>
                </Col>

                <Col span={8}>
                    <Card title="Yayında Olmayan Kelime" className="mt-5 text-center bg-red-200 shadow-lg" >
                        <div className="font-bold text-[40px]">{dataSource.unpublishedWordCount}</div>
                    </Card>
                </Col>
                
                <Col span={8}>
                    <Card title="Toplam Üye" className="mt-5 text-center bg-purple-200 shadow-lg" >
                        <div className="font-bold text-[40px]">{dataSource.totalCustomerCount}</div>
                    </Card>
                </Col>

            </Row>
        
        </>
    );
}

export default GeneralStatistic;