import React, { FC } from "react";
import { Navigate } from "react-router-dom";

interface RoleBasedRouteProps {
    children: React.ReactNode;
    allowedRoles: string[];
}

const RoleBasedRoute: FC<RoleBasedRouteProps> = ({ children, allowedRoles }) => {
    const userData = localStorage.getItem("userData");
    const userRole = userData ? JSON.parse(userData).role : null;

    return userRole && allowedRoles.includes(userRole) ? (
        <>{children}</>
    ) : (
        <Navigate to="/unauthorized" />
    );
};

export default RoleBasedRoute;
