import { useEffect, useState } from "react";
import PanelPageTitle from "../../../components/PanelPageTitle";
import UserService from "../../../services/UserService";
import { message } from "antd";
import Loading from "../../../components/Loading";
import AddUserModal from "./components/AddUserModal";
import UserTable from "./components/UserTable";
import { faUsers } from "@fortawesome/free-solid-svg-icons";

const User = () =>{

    const [dataSource, setDataSource] = useState([]);
    const _userService = UserService();
    const [isLoadingUserList, setIsLoadingUserList]= useState(false)

    useEffect(() =>{
        getUser();        
    }, []) //? Boş dizi yalnızca ilk yüklendiğinde derlenir

   

    
    const getUser = async ()=>{
        try { 

            setIsLoadingUserList(true);
            var response = await _userService.getUser();
            
            if (response.statusCode == 200 ) {
                const formattedData = response.data.map((item:any, index:any) => ({
                    key: index + 1, 
                    action:{
                        id: item.userId,
                        name: item.name,
                    },
                    name: item.name,
                    surname:item.surname,
                    username:item.username,
                    role:item.role,
                    status:item.status,
                    
                }));
                //console.log('response: ', response);
                
                setDataSource(formattedData);
            }
            
            response.errors.forEach((i:any) => {                
                message.error(i)
            });
        } catch (err) {
            console.log("getUser :", err);
        }finally{
            setIsLoadingUserList(false);

        }
        
    }
    return(
        <>
            <PanelPageTitle icon={faUsers} bg="bg-[#3498DB]" title="Kullanıcı Listesi"/>

            <AddUserModal onUserAdded={getUser}/>
            
            {
                isLoadingUserList ==true ?
                (<Loading/>):
                (
                    <div className="mt-5">
                        <UserTable onUserUpdated={getUser} onUserDeleted={getUser} dataSource={dataSource}/>
                    </div>
                )
            }


        </>
    );
}
export default User;