import NotFoundPage from "../pages/empty/NotFoundPage";
import CustomerRoutes from "./CustomerRoutes";
import EmployeeRoutes from "./EmployeeRoutes";
import EmptyRoutes from "./EmptyRoutes";
import ManagerRoutes from "./ManagerRoutes";

const Routes = [
    ...CustomerRoutes, 
    ...ManagerRoutes, 
    ...EmployeeRoutes, 
    ...EmptyRoutes, 
];

export default Routes;
