import { Link } from "react-router-dom";


const Unauthorized = () => {
    return (
      <>
        <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-red-600 to-gray-700 text-white">
          <div className="text-center px-6 py-10 bg-opacity-50 bg-white rounded-lg shadow-xl backdrop-blur-lg">
            <h1 className="text-7xl font-bold mb-4">Yetkiniz Bulunamadı !</h1>
            <p className="mb-6">
              Üzgünüz, bu sayfayı görüntülemek için gerekli yetkiniz bulunmamaktadır. Lütfen sistem yöneticisiyle iletişime geçin.
            </p>

            <Link to="/" className="inline-block px-6 py-3 bg-blue-700 rounded-lg hover:bg-blue-800 transition duration-200">
                    Anasayfaya Dön
            </Link>
          </div>
        </div>
      </>
    );
  }
  
  export default Unauthorized;
  
  