import { CheckCircleOutlined, CheckOutlined, CloseCircleOutlined, CloseOutlined, DeleteOutlined, EditOutlined, SearchOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { faEdit, faTrashAlt, faTrashArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table, Button , Tooltip, Popconfirm, message} from "antd";
import UpdateUserModal from "./UpdateUserModal";
import UserService from "../../../../services/UserService";

const UserTable = (props:any) =>{

    const _userService = UserService();
    
    const deleteUser= async(userId:string)=>{

        try {

            var data={
                userId:userId
            }

            var response = await _userService.deleteUser(data);
            console.log('response: ', response);
            if (response.statusCode == 200) {
                message.success("Kullanıcı silindi")

                props.onUserDeleted();
            }
            response.errors.forEach((i:any) => {                
                message.error(i)
            });
            
        } catch (err) {
            console.log("delete user",err)
        }
    }

    const handleUserUpdated = ()=>{
        props.onUserUpdated();
    }


    const columns = [
        {
            title: '#',
            width: 50,
            dataIndex: 'key',
            render: (text: any) => <span className="font-bold">{text}</span>,
        },
        
        {
            title: 'Adı',
            dataIndex: 'name',
        },
        {
            title: 'Soyadı',
            dataIndex: 'surname',
        },
        {
            title: 'Kullanıcı Adı',
            dataIndex: 'username',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            render: (role:number) => (role === 0 ? 'Yönetici' : 'Çalışan'),
        },
        {
            title: 'Durumu',
            dataIndex: 'status',
            render: (status:number) => (status == 0 ? <CloseOutlined  style={{ color: 'red' }}  /> : <CheckOutlined  style={{ color: 'green' }}  />),
        },
        {
            title: '',
            width: 120,
            dataIndex: 'action',
            render: (action: { id: string; name: string }) => (
                <>

                    <Popconfirm
                        title="Kullanıcıyı silmek istiyor musun?"
                        okText="Sil"
                        onConfirm={e=>deleteUser(action.id)}
                        cancelText="İptal"
                    >
                        <Tooltip title="Sil">
                            <Button type="primary" className="bg-red-500 hover:!bg-red-400" icon={<DeleteOutlined />}  />
                        </Tooltip>
                    </Popconfirm>

                    <Tooltip title="Güncelle">
                        <>
                            <UpdateUserModal onUserUpdated={handleUserUpdated} userId={action.id} userName={action.name} />
                        </>
                    </Tooltip>

                </>
                    
            ),
        },
        
    ];

    return(
        <>
            <Table dataSource={props.dataSource} columns={columns} />
        </>
    );

}

export default UserTable;