import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Input, message, Modal, Select, Switch } from "antd";
import { useEffect, useState } from "react";
import Loading from "../../../../components/Loading";
import UserService from "../../../../services/UserService";

const UpdateUserModal = (props:any) =>{
    
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [username, setUsername] = useState("");
    const [mail, setMail] = useState("");
    const [gender, setGender] = useState("Cinsiyeti Seçiniz");
    const [role, setRole] = useState("Rolü Seçiniz");
    const [companyName, setCompanyName] = useState("");
    const [isChecked, setIsChecked] = useState(true);
    const _userService = UserService();
    const [isLoadingUser, setIsLoadingUser]= useState(false)
    const [isLoadingUpdateUser, setIsLoadingUpdateUser]= useState(false)


    const handleChange = (checked:boolean) => {
        setIsChecked(checked);
    };

    const showModal = () => {
        setIsModalOpen(true);
        getByIdUser();
    };

    const validatorUser = (data:any)=>{

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (data.name == "") {
            message.error("Ad boş geçilemez !")
            return false;
        }
        if (data.surname == "") {
            message.error("Soyad boş geçilemez !")
            return false;

        }
        if (data.username == "") {
            message.error("Kullanıcı adı boş geçilemez !")
            return false;

        }
        if (data.mail == "") {
            message.error("Mail boş geçilemez !")
            return false;
        }
        if (!emailRegex.test(data.mail)) {
            message.error("Geçersiz e-posta formatı !")
            return false;
        }
        if (data.companyName == "") {
            message.error("Firma boş geçilemez !")
            return false;
        }
        if (data.gender == "Cinsiyeti Seçiniz") {
            message.error("Cinsiyet boş geçilemez !")
            return false;
        }
        if (data.role == "Rolü Seçiniz") {
            message.error("Rol boş geçilemez !")
            return false;
        }

        return true;
        
    }

    const handleOk = async () => {
        try {

            var data ={
                userId:props.userId ,
                name: name,
                surname:surname,
                username:username,
                mail:mail,
                gender:gender,
                companyName:companyName,
                role:role,
                status:isChecked==true ? 1 :0,
            }
            console.log('data: ', data);
            var result =validatorUser(data)
            if (result == false) {
                return
            }
            setIsLoadingUpdateUser(true)
            var response = await _userService.updateUser(data);
            console.log('response: ', response);
            if (response.statusCode == 200) {
                message.success("Kullanıcı güncellendi")
                setIsModalOpen(false);
                
                // callback'i çağır
                props.onUserUpdated();
                
                
            }
            response.errors.forEach((i:any) => {                
                message.error(i)
            });
            
        } catch (err) {
            console.log("UpdateUserModal",err)
        }
        finally{
            setIsLoadingUpdateUser(false)

        }
    };

    const handleCancel = () =>{
        setIsModalOpen(false)
    }

    const getByIdUser = async ()=>{
        try { 

            const data = {
                userId :props.userId 
            }
            setIsLoadingUser(true);
            var response = await _userService.getByIdUser(data);
            
            if (response.statusCode == 200 ) {
               
                console.log('response: ', response);
                setName(response.data.name)
                setSurname(response.data.surname)
                setUsername(response.data.username)
                setMail(response.data.mail)
                setCompanyName(response.data.companyName)
                setGender(response.data.gender)
                setRole(String(response.data.role))
              
                if (response.data.status == 1) {
                    setIsChecked(true)
                }
                else{
                    setIsChecked(false)
                }
            }
            
            response.errors.forEach((i:any) => {                
                message.error(i)
            });
        } catch (err) {
            console.log("getByIdUser :", err);
        }finally{
            setIsLoadingUser(false);
        }
        
    }

    return(
        <>


            <Button type="primary" onClick={showModal} className="bg-yellow-500 ml-1 mr-1 hover:!bg-yellow-400" icon={<EditOutlined />}  />


            <Modal title= {<span> <span className="font-bold ">{props.userName}</span> Kullanıcısını Güncelleme</span>}
                okText="Güncelle"
                cancelText="İptal" 
                maskClosable={false} 
                okButtonProps={{ className: 'bg-green-500 text-white hover:!bg-green-300' }}  
                open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>

                    {
                        isLoadingUser == true || isLoadingUpdateUser == true ?
                        <Loading/> :<></>
                      
                    }

<>
                        <div className='mt-5'>
                        
                            <Input className='mt-3 h-[40px]' placeholder="Adı" value={name} onChange={e=>{setName(e.target.value)}} />
                            <Input className='mt-3 h-[40px]' placeholder="Soyadı" value={surname} onChange={e=>{setSurname(e.target.value)}} />
                            <Input className='mt-3 h-[40px]' placeholder="Kullanıcı adı" value={username} onChange={e=>{setUsername(e.target.value)}} />
                            <Input className='mt-3 h-[40px] !text-gray-500' disabled placeholder="Mail" type='email' value={mail} onChange={e=>{setMail(e.target.value)}} />

                            <Select
                                placeholder="Cinsiyeti Seçiniz"
                                value={gender}
                                onChange={e => setGender(e)}
                                className='w-full mt-3 h-[40px]'
                                options={[
                                    { value: 'E', label: 'Erkek' },
                                    { value: 'K', label: 'Kız' },
                                ]}
                                />
                
                            <Select
                                value={role}
                                onChange={e => setRole(e)}
                                placeholder="Rolü Seçiniz"
                                className='w-full mt-3 h-[40px]'
                                options={[
                                    { value: '0', label: 'Yönetici' },
                                    { value: '1', label: 'Çalışan' },
                                ]}
                                />


                                <div className=' mt-3 flex items-start gap-2'>
                                    <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    defaultChecked
                                    checked={isChecked}
                                    onChange={handleChange}
                                    style={{
                                        backgroundColor: isChecked ? "green" : "red",
                                    }}
                                    />

                                    <span className="font-mono font-bold">{isChecked ? "Aktif":"Pasif"}</span>
                                </div>
                            </div>

                        
                        </>



                

            </Modal>
        
        </>
    );
}

export default UpdateUserModal;
