import axios from '../interceptors/AuthInterceptor';
import { IAddHeadWord } from '../models/headWord/IAddHeadWord';
import { IGetHeadWordRequest } from '../models/headWord/IGetHeadWordRequest';
import { IHeadWordIdRequest } from '../models/headWord/IHeadWordIdRequest';
import { ISearchHeadWordRequest } from '../models/headWord/ISearchHeadWordRequest';
import { IUpdateHeadWord } from '../models/headWord/IUpdateHeadWord';

const HeadWordService =()=> {

    const apiUrl = process.env.REACT_APP_API_URL


    const addHeadWord = async (data:IAddHeadWord) => {  
        try { 

            const formData = new FormData();
                        
            formData.append("HeadWord.Name", data.headWord.name);
            formData.append("HeadWord.HomophoneCode", data.headWord.homophoneCode);
            formData.append("HeadWord.DictionaryFormat", data.headWord.dictionaryFormat);
            formData.append("HeadWord.SoundUrl", data.headWord.soundUrl);

            data.tematicId.forEach((id) => {
                formData.append("TematicId", id);
            });
            
            data.wordFamily.forEach((item) => {
                formData.append("WordFamily", item);
            });
            
            const response = await axios.post(
                `${apiUrl}/HeadWord/add-head-word`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };
    const updateHeadWord = async (data:IUpdateHeadWord) => {  
        try { 
            console.log('data: updateHeadWord ', data);
            
            const formData = new FormData();
            
            formData.append("HeadWord.HeadWordId", data.headWord.headWordId!);
            formData.append("HeadWord.Name", data.headWord.name);
            formData.append("HeadWord.HomophoneCode", data.headWord.homophoneCode);
            formData.append("HeadWord.DictionaryFormat", data.headWord.dictionaryFormat);
            formData.append("HeadWord.SoundUrl", data.headWord.soundUrl);
            
            data.tematicId.forEach((id) => {
                formData.append("TematicId", id);
            });
            
            data.wordFamily.forEach((item) => {
                formData.append("WordFamily", item);
            });
            console.log('formData: ', formData);
            
            const response = await axios.post(
                `${apiUrl}/HeadWord/update-head-word`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };
    const getByIdHeadWord = async (data:IHeadWordIdRequest) => {  
        try { 
            
            const response = await axios.post(`${apiUrl}/HeadWord/get-by-id-head-word`,data);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };
    const deleteHeadWord = async (data:IHeadWordIdRequest) => {  
        try { 
            
            const response = await axios.post(`${apiUrl}/HeadWord/delete-head-word`,data);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };

    const getHeadWord = async (data:IGetHeadWordRequest) => {  
        try { 
            
            const response = await axios.post(`${apiUrl}/HeadWord/get-head-word`,data);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };
    
    const searchHeadWord = async (data:ISearchHeadWordRequest) => {  
        try { 
            
            const response = await axios.post(`${apiUrl}/HeadWord/search-head-word`,data);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };


    return {
        addHeadWord,
        getHeadWord,
        deleteHeadWord,
        getByIdHeadWord,
        updateHeadWord,
        searchHeadWord,

    };


}

export default HeadWordService;
