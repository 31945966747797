import { CheckCircleOutlined, CheckOutlined, CloseCircleOutlined, CloseOutlined, DeleteOutlined, EditOutlined, SearchOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { faEdit, faFontAwesome, faPlusCircle, faTrash, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table, Button , Tooltip, Popconfirm, message} from "antd";
import AddSubTematicModal from "./AddSubTematicModal";
import TematicService from "../../../../services/TematicService";
import UpdateTematicModal from "./UpdateTematicModal";

const TematicTable = (props:any) =>{

    const _tematicService = TematicService();
    const deleteTematic= async(tematicId:string)=>{

        try {

            var data={
                tematicId:tematicId
            }

            var response = await _tematicService.deleteTematic(data);
            console.log('response: ', response);
            if (response.statusCode == 200) {
                message.success("Tematic silindi")
                props.onTematicDeleted();
            }
            response.errors.forEach((i:any) => {                
                message.error(i)
            });
            
        } catch (err) {
            console.log("delete tematic",err)
        }
    }





    const columns = [
        {
            title: '#',
            width: 150,
            dataIndex: 'key',
            render: (text: any) => <span className="font-bold">{text}</span>,
        },
        {
            title: 'Fotoğraf',
            dataIndex: 'imgUrl',
            render: (text: string) => 
                <>
                    {
                        text == null || text =="" ?
                        <img className="w-[100px] h-[100px] rounded-lg" src={process.env.REACT_APP_FILE+"/uploads/no-image-found.png"} /> :
                        <img className="w-[100px] h-[100px] rounded-lg" src={process.env.REACT_APP_FILE+text} /> 
                    }
                </>
        },
        {
            title: 'Tematik Adı',
            dataIndex: 'name',
        },
        {
            title: 'Sıralama',
            dataIndex: 'orderCode',
        },
        {
            title: '',
            width: 350,
            dataIndex: 'action',
            render: (action: { id: string; name: string }) => (
                <>
                    <Tooltip title="Güncelle">
                        <>
                            <UpdateTematicModal onTematicUpdated={()=>{props.onTematicUpdated()}} tematicId={action.id} tematicName={action.name}/>
                        </>
                    </Tooltip>
                    
                    <Tooltip title="Alt Tematik Ekle">
                        <>
                            <AddSubTematicModal parentId={action.id} parentName={action.name}/>
                        </>
                    </Tooltip>

                    <Popconfirm
                        title="Tematiği silmek istiyor musunuz?"
                        okText="Sil"
                        onConfirm={e=>deleteTematic(action.id)}
                        cancelText="İptal"
                    >
                        <Tooltip title="Sil">
                            <Button type="primary" className="bg-red-500 hover:!bg-red-400">
                                <FontAwesomeIcon icon={faTrashAlt} size="1x"  />
                            </Button>
                        </Tooltip>
                    </Popconfirm>

                   
                </>
                    
            ),
        },

        
    ];

    return(
        <>
            <Table dataSource={props.dataSource} columns={columns} locale={{ emptyText: <span className="text-red-400 font-bold">Tematik bulunamadı !</span>}}  />
        </>
    );

}

export default TematicTable;