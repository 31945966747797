import EmptyLayout from "../layouts/emptyLayout/EmptyLayout";
import Login from "../pages/empty/Login";
import NotFoundPage from "../pages/empty/NotFoundPage";
import Unauthorized from "../pages/empty/Unauthorized";

const EmptyRoutes = [
    {
        element: <EmptyLayout />,
        children: [
            { path: 'login', element: <Login /> },
            { path: 'unauthorized', element: <Unauthorized /> },
            { path: '*', element: <NotFoundPage /> }, 
        ],
    },
];

export default EmptyRoutes;