import axios from '../interceptors/AuthInterceptor';
import { IAddUser } from '../models/user/IAddUser';
import { IChangePassword } from '../models/user/IChangePassword';
import { IUpdateMyInformation } from '../models/user/IUpdateMyInformation';
import { IUpdateUser } from '../models/user/IUpdateUser';
import { IUserIdRequest } from '../models/user/IUserIdRequest';

const UserService =()=> { 
    
    const apiUrl = process.env.REACT_APP_API_URL

    const getTokenUser = async () => {  
        try { 
            /* ${process.env.REACT_APP_API_URL} */
            const response = await axios.get(`${apiUrl}/User/get-token-user`,);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };

    const getUser = async () => {  
        try { 
            
            const response = await axios.get(`${apiUrl}/User/get-user`,);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };

    const getByMyInformation = async () => {  
        try { 
            
            const response = await axios.post(`${apiUrl}/User/get-by-my-information`,);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };

    const addUser = async (data:IAddUser) => {  
        try { 
            
            const response = await axios.post(`${apiUrl}/User/add-user`,data);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };

    const updateMyInformation = async (data:IUpdateMyInformation) => {  
        try { 
            
            const response = await axios.post(`${apiUrl}/User/update-my-information`,data);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };

    const changePassword = async (data:IChangePassword) => {  
        try { 
            
            const response = await axios.post(`${apiUrl}/User/change-password`,data);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };

    const deleteUser = async (data:IUserIdRequest) => {  
        try { 
            
            const response = await axios.post(`${apiUrl}/User/delete-user`,data);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };

    const getByIdUser = async (data:IUserIdRequest) => {  
        try { 
            
            const response = await axios.post(`${apiUrl}/User/get-by-id-user`,data);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };

    const updateUser = async (data:IUpdateUser) => {  
        try { 
            
            const response = await axios.post(`${apiUrl}/User/update-user`,data);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };

    return {
        getTokenUser,
        getUser,
        addUser,
        getByMyInformation,
        updateMyInformation,
        changePassword,
        deleteUser,
        getByIdUser,
        updateUser
    };


}

export default UserService;
