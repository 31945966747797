import { Button, Input, message, Select } from "antd";
import PanelPageTitle from "../../../components/PanelPageTitle";
import { PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { faArrowDownAZ, faClock, faFileLines, faFileWord, faSearch } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../../components/Loading";
import HeadWordTable from "./components/HeadWordTable";
import { useEffect, useState } from "react";
import HeadWordService from "../../../services/HeadWordService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IGetHeadWordRequest } from "../../../models/headWord/IGetHeadWordRequest";

const HeadWord = () =>{

    const [isLoadingHeadWordList, setIsLoadingHeadWordList]= useState(false)
    const [dataSource, setDataSource] = useState([]);
    const [orderHeadWord, setOrderHeadWord] = useState("a-z");
    const [headWordName, setHeadWordName] = useState("");
    const _headService = HeadWordService();

    const [ userData, setUserData ] = useState(Object);

    useEffect(() =>{
        const storedData = localStorage.getItem("userData");
        const parsedData = storedData ? JSON.parse(storedData) : null;
        setUserData(parsedData)
        getHeadWord();        
    }, []) //? Boş dizi yalnızca ilk yüklendiğinde derlenir

    const getHeadWord = async ()=>{
        try { 

            setIsLoadingHeadWordList(true);
            var data : IGetHeadWordRequest={
                    headWordName: headWordName,
                    orderHeadWord : orderHeadWord,
            }
            var response = await _headService.getHeadWord(data);
            
            if (response.statusCode == 200 ) {
                const formattedData = response.data.map((item:any, index:any) => ({
                    key: index + 1, 
                    action:{
                        id: item.headWordId,
                        name: item.name,
                    },
                    name: item.name,
                    homophoneCode:item.homophoneCode,

                }));
                console.log('response: ', response);
                
                setDataSource(formattedData);
            }
            
            response.errors.forEach((i:any) => {                
                message.error(i)
            });
        } catch (err) {
            console.log("getHeadWord :", err);
        }finally{
            setIsLoadingHeadWordList(false);
        }
        
    }
    return(
        <>
            <PanelPageTitle icon={faFileLines} bg="bg-[#3498DB]" title="Madde Başı Listesi"/>


            <Link  to={userData.role === "Manager" ? "/manager/add-head-word" : "/employee/add-head-word"}>
                <Button type="primary"  className="bg-green-500 hover:!bg-green-400 mt-4" icon={<PlusOutlined />} >
                    Madde Başı Ekle
                </Button>
            </Link>

            {
                isLoadingHeadWordList ==true ?
                (<Loading/>):
                (
                    <div className="mt-5">
                        <div className="mb-3  flex justify-end">
                            <div className=" flex items-center gap-2 w-[50%]">
                                <Select
                                    value={orderHeadWord}
                                    onChange={e=>setOrderHeadWord(e)}
                                    placeholder="Seçiniz"
                                    className='w-full'
                                    options={[
                                        { value: 'a-z', label: <>
                                            <FontAwesomeIcon icon={faArrowDownAZ} size="1x" /> 
                                            <span className="ml-2">Madde başı a-z</span>
                                        </> 
                                        },
                                        { value: 'create-date', label:
                                            <>
                                            <FontAwesomeIcon icon={faClock} size="1x" /> 
                                            <span className="ml-2">En son eklenme tarihi</span>
                                        </> },
                                    ]}
                                    />
                                <Input value={headWordName} onChange={e=>setHeadWordName(e.target.value)} placeholder="Madde başı"/>
                                <Button onClick={() => getHeadWord()}>
                                    <FontAwesomeIcon icon={faSearch} size="1x" />
                                </Button>
                            </div>
                        </div>
                        <HeadWordTable dataSource={dataSource} onHeadWordDeleted={getHeadWord}/>
                    </div>
                )
            }



        </>
    );
}
export default HeadWord;