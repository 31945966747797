import EmployeeLayout from "../layouts/employeeLayout/EmployeeLayout";
import Dashboard from "../pages/employee/Dashboard";

import RoleBasedRoute from "../guards/RoleBasedRoute";
import HeadWord from "../pages/common/headWord/HeadWord";
import AddHeadWord from "../pages/common/headWord/AddHeadWord";
import UpdateHeadWord from "../pages/common/headWord/UpdateHeadWord";
import Meaning from "../pages/common/meaning/Meaning";
import AddMeaning from "../pages/common/meaning/AddMeaning";
import UpdateMeaning from "../pages/common/meaning/UpdateMeaning";
import MyInformation from "../pages/common/myInformation/MyInformation";

const EmployeeRoutes = [
    {
        path: 'employee',
        element: 
            <RoleBasedRoute allowedRoles={["Employee"]}>
                <EmployeeLayout />
            </RoleBasedRoute>,
        children: [
            { path: 'dashboard', element: <Dashboard /> },
            { path: 'head-word', element: <HeadWord /> },
            { path: 'add-head-word', element: <AddHeadWord /> },
            { path: 'update-head-word/:headWordId', element: <UpdateHeadWord /> },
            { path: 'meaning/:headWordId', element: <Meaning /> },
            { path: 'add-meaning/:headWordId', element: <AddMeaning /> },
            { path: 'update-meaning/:headWordId/:meaningId', element: <UpdateMeaning /> },
            { path: 'my-information', element: <MyInformation /> },
        ],
    },
];

export default EmployeeRoutes;