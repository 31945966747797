import { useState } from "react";
import AuthService from "../../services/AuthService";
import { message, Input, Button  } from 'antd';
import { EditOutlined, UserOutlined } from '@ant-design/icons';
import UserService from "../../services/UserService";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";

const Login = () => {



    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isLoadingLogin, setIsLoadingLogin]= useState(false)
    const _authService = AuthService();
    const _userService = UserService();
    const navigate = useNavigate();

    const handleLogin= async()=>{
                
        const data = { username, password }; 

        try { 
            setIsLoadingLogin(true);
            const response = await _authService.login(data); 
            console.log("handleLogin :", response);
            if (response.statusCode == 200) {
                localStorage.setItem('accessToken', response.data.accessToken);
                message.success('Giriş Başarılı');

                var responseUser = await _userService.getTokenUser();
                if (response.statusCode == 200) {
                    var userData = {
                        name : responseUser.data.name,
                        surname : responseUser.data.surname,
                        username : responseUser.data.username,
                        role :  
                            responseUser.data.role === 0 ? "Manager" : 
                            responseUser.data.role === 1 ? "Employee" : 
                            responseUser.data.role === 2 ? "Customer" : "-",
                        gender: responseUser.data.gender,
                    }

                    localStorage.setItem('userData', JSON.stringify(userData));
                    if (responseUser.data.role == 0) {
                        
                        navigate('/manager/dashboard'); 
                    }
                    else if (responseUser.data.role == 1) {
                        
                        navigate('/employee/dashboard'); 
                    }
                    else if (responseUser.data.role == 2) {
                        
                        navigate('/'); 
                    }
                    
                }

            }

            response.errors.forEach((i:any) => {                
                message.error(i)
            });
        } catch (err:any) {
            console.log("handleLogin :", err);
        }
        finally{
            setIsLoadingLogin(false);
        }


    }

    const handleKeyDown = (e:any) => {
        if (e.key === 'Enter') {
            handleLogin(); 
        }
    };

    return (
        <>
            <div onKeyDown={handleKeyDown} className="bg-gradient-to-r from-blue-500 to-purple-500  h-screen flex items-center justify-center" >
            {
                isLoadingLogin == true ?
                <Loading />
                : <></>
                
            }

                <div className="bg-white p-[30px] rounded-xl">

                    <h1 className="text-center text-black font-bold text-[30px] mb-3">Turkces </h1>

                    <Input size="large" 
                    className="p-3"
                    value={username}
                    onChange={e=>setUsername(e.target.value)}
                    placeholder="Kullanıcı adınız" prefix={<UserOutlined />} />

                    <Input.Password size="large" 
                    className="mt-3 p-3"
                    type="password"
                    value={password}
                    onChange={e=>setPassword(e.target.value)}
                    placeholder="Şifreniz" prefix={<EditOutlined />} />

                    <div className="flex justify-center">
                        <Button type="default" className="bg-gray-500 text-white mt-3 min-w-40 "  onClick={handleLogin} shape="round" size={"large"}>
                            Giriş Yap
                        </Button>
                    </div>
                

                </div>


            </div>
        </>
    );
};

export default Login;
