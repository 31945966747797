import { Link } from "react-router-dom";

const NotFoundPage = ()=>{
    return(
        <>
            <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-500 to-purple-600 text-white">
            <div className="text-center px-6 py-10 bg-white bg-opacity-20 backdrop-blur-lg rounded-lg shadow-lg">
                <h1 className="text-7xl font-bold mb-4">404</h1>
                <h2 className="text-2xl font-semibold mb-2">
                    Aradığınız Sayfa Bulunamadı!
                </h2>
                <p className="mb-6">
                    Üzgünüz, aradığınız sayfa mevcut değil ya da kaldırılmış olabilir.
                </p>
                
                <Link to="/" className="inline-block px-6 py-3 bg-blue-700 rounded-lg hover:bg-blue-800 transition duration-200">
                    Anasayfaya Dön
                </Link>
            
            </div>
        </div>
        </>
    );

}

export default NotFoundPage;
