import { faFileInvoice, faLinesLeaning } from "@fortawesome/free-solid-svg-icons";
import PanelPageTitle from "../../../components/PanelPageTitle";
import { Breadcrumb, Button, message } from "antd";
import { Link, useParams } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import MeaningTable from "./components/MeaningTable";
import { useEffect, useState } from "react";
import Loading from "../../../components/Loading";
import HeadWordService from "../../../services/HeadWordService";
import MeaningService from "../../../services/MeaningService";
import { IHeadWordIdRequest } from "../../../models/headWord/IHeadWordIdRequest";



const Meaning = () =>{

    const { headWordId } = useParams();

    const [isLoadingGetHeadWord, setIsLoadingGetHeadWord]= useState(false)
    const [isLoadingMeaningList, setIsLoadingMeaningList]= useState(false)
    const [headWordName, setHeadWordName]= useState("") 
    const [headWordHomophoneCode, setHeadWordHomophoneCode]= useState("") 
    const _headWordService = HeadWordService();
    const _meaningService = MeaningService();
    const [dataSource, setDataSource] = useState([]);

    const [ userData, setUserData ] = useState(Object);


    useEffect(()=>{
        const storedData = localStorage.getItem("userData");
        const parsedData = storedData ? JSON.parse(storedData) : null;
        setUserData(parsedData)
        getByIdHeadWord()   
        
    },[])

    useEffect(()=>{
        if (headWordName != "") {
            getMeaning()   
            //console.log('headWordName: ', headWordName);
        }
        
    },[headWordName])

    const getByIdHeadWord = async() =>{
        try {
            setIsLoadingGetHeadWord(true);
            const data = {
                headWordId : headWordId!
            }
            var response = await _headWordService.getByIdHeadWord(data);
            console.log('response: ', response);

            if (response.statusCode == 200 ) {

                if (response.data != null) {
                    setHeadWordName(response.data.name)
                    setHeadWordHomophoneCode(response.data.homophoneCode)    
                }
            }
            
            response.errors.forEach((i:any) => {                
                message.error(i)
            });
        } catch (err) {
            console.log('err: ', err);
        }
        finally{
            setIsLoadingGetHeadWord(false);
        }
    }

    const getMeaning = async ()=>{
        try { 

            setIsLoadingMeaningList(true);
            var data :IHeadWordIdRequest={
                headWordId : headWordId!
            }
            var response = await _meaningService.getMeaning(data);
            
            if (response.statusCode == 200 ) {
                const formattedData = response.data.map((item:any, index:any) => ({
                    key: index + 1, 
                    action:{
                        id: item.meaningId,
                        isPublished:item.isPublished,
                    },
                    name:headWordName,
                    homophoneCode:headWordHomophoneCode,
                    order: item.order,
                    wordType:item.wordType,
                    languageLevel:item.languageLevel
                    
                }));
                console.log('formattedData: ', formattedData);
                console.log('response: ', response);
                
                setDataSource(formattedData);
            }
            
            response.errors.forEach((i:any) => {                
                message.error(i)
            });
        } catch (err) {
            console.log("getMeaning :", err);
        }finally{
            setIsLoadingMeaningList(false);
        }
        
    }

    return(
        <>
            <PanelPageTitle icon={faFileInvoice} bg="bg-[#3498DB]" title="Anlam Listesi"/>

            <Breadcrumb
                    className="mt-4"
                    items={[
                        {
                            title: <Link to={userData.role === "Manager" ? "/manager/head-word" : "/employee/head-word"} >Madde Başı Listesi</Link>,
                        },
                        {
                            title: 'Anlam Listesi',
                        },
                    ]}
                />
            

            {
                isLoadingGetHeadWord == true || isLoadingMeaningList == true ?
                <Loading /> :
                <></>
            }

            <Link to={userData.role === "Manager" ? "/manager/add-meaning/"+headWordId : "/employee/add-meaning/"+headWordId} >
                <Button type="primary"  className="bg-green-500 hover:!bg-green-400 mt-4" icon={<PlusOutlined />} >
                    Anlam Ekle
                </Button>
            </Link>

            <br />
            <br />

            <div className="text-center mb-3 text-[25px] bg-yellow-300 opacity-50 p-3 rounded-lg">
                <span className="font-bold"> {headWordName} - {headWordHomophoneCode} </span>
            </div>
            
            <MeaningTable headWordId={headWordId} dataSource={dataSource} onUpdatePublishedMeaning={getMeaning} onMeaningDeleted={getMeaning} />

        </>
    );
}
export default Meaning;