import React, { useState } from 'react';
import { Button, Modal, Input, message, Upload, UploadFile, UploadProps } from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import Loading from '../../../../components/Loading';
import TematicService from '../../../../services/TematicService';

const AddTematicModal = ({ onTematicAdded }:any) =>{


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [name, setName] = useState("");
    const [orderCode, setOrderCode] = useState("");
    const [isLoadingAddTematic, setIsLoadingAddTematic]= useState(false)
    const _tematicService = TematicService();

    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const validatorTematic = (data:any)=>{

    
        if (data.name == "") {
            message.error("Tematik boş geçilemez !")
            return false;
        }
        if (data.orderCode == "") {
            message.error("Sıralama geçilemez !")
            return false;
        }
    

        return true;
        
    }

    const handleOk = async () => {
        try {


            const file: File | null = fileList.find((file) => file.originFileObj)?.originFileObj as File;
            
            var data ={
                name: name,
                orderCode : orderCode,
                tematicImg : file
            }
            var result =validatorTematic(data)
            if (result == false) {
                return
            }
            setIsLoadingAddTematic(true);
            var response = await _tematicService.addTematic(data);
            console.log('response: ', response);
            if (response.statusCode == 200) {
                message.success("Tematik Eklendi")
                setIsModalOpen(false);
                setName("");
                setOrderCode("")
                setFileList([])
                // callback'i çağır
                onTematicAdded();
                
                
            }
            response.errors.forEach((i:any) => {                
                message.error(i)
            });
            
        } catch (err) {
            console.log("AddTematicModal",err)
        }
        finally{
            setIsLoadingAddTematic(false);
        }
    };

    const handleCancel = () =>{

        setIsModalOpen(false)
        setName("");
        setOrderCode("")
        setFileList([])

    }

    const propsUpload: UploadProps = {
        onChange: ({ file, fileList }) => {
            setFileList(fileList); // Yeni dosya listesini güncelle
        },
        onRemove: (file) => {
            setFileList((prev) => prev.filter((item) => item.uid !== file.uid)); // Dosyayı kaldır
        },
        beforeUpload: (file) => {
            return false; // Yüklemeyi durdur
        },
        fileList,
    };


    return(
        <>

        <Button
        onClick={showModal}
        className="mt-3 bg-green-500 hover:!bg-green-300"
        icon={<PlusOutlined />}
        type="primary"
        >
            Tematik Ekle
        </Button>

        <Modal title="Tematik Ekle" 
            okText="Kaydet"
            cancelText="İptal" 
            maskClosable={false} 
            okButtonProps={{ className: 'bg-green-500 text-white hover:!bg-green-300' }}  
            open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>

                <div className='mt-5'>
                    <Input className='h-[40px]' placeholder="Tematik adı" value={name} onChange={e=>{setName(e.target.value)}} />
                </div>

                <div className='mt-5'>
                    <Input className='h-[40px]' type='number' placeholder="Sıralama" value={orderCode} onChange={e=>{setOrderCode(e.target.value)}} />
                </div>

                <div className="mt-5">
                    <Upload {...propsUpload} maxCount={1} accept="image/*">
                        <Button icon={<UploadOutlined />}>Görsel Ekleyin</Button>
                    </Upload>
                </div>

                {
                    isLoadingAddTematic == true ? 
                    <Loading/>:<></>
                }
        </Modal>
        
        </>
    );
}

export default AddTematicModal;