import axios from '../interceptors/AuthInterceptor';
import { IHeadWordIdRequest } from '../models/headWord/IHeadWordIdRequest';
import { IAddMeaning } from '../models/meaning/IAddMeaning';
import { IMeaningIdRequest } from '../models/meaning/IMeaningIdRequest';
import { IUpdateMeaning } from '../models/meaning/IUpdateMeaning';
import { IAddSubTematic } from '../models/tematic/IAddSubTematic';
import { IAddTematic } from '../models/tematic/IAddTematic';
import { IAddUser } from '../models/user/IAddUser';

const MeaningService =()=> {

    const apiUrl = process.env.REACT_APP_API_URL

    const getMeaning = async (data:IHeadWordIdRequest) => {  
        try { 
            
            const response = await axios.post(`${apiUrl}/Meaning/get-meaning`,data);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };


    const addMeaning = async (data:IAddMeaning) => {  
        try { 
            
            const formData = new FormData(); 

            formData.append("HeadWordId", data.headWordId);
            formData.append("Order", data.order);
            formData.append("WordType", data.wordType);
            formData.append("Definition", data.definition);
            formData.append("LanguageLevel", data.languageLevel);
            formData.append("LanguageEnglish", data.languageEnglish);
            formData.append("LanguageRussian", data.languageRussian);
            formData.append("LanguageCroatian", data.languageCroatian);
            formData.append("LanguageArabic", data.languageArabic);
            data.exampleSentence.forEach((item) => {
                formData.append("ExampleSentence", item);

            });
            data.file.forEach((fileItem, index) => {
                if (fileItem.description) {
                    formData.append(`File[${index}].Description`, fileItem.description);
                }
                if (fileItem.image && fileItem.image.originFileObj) {
                    formData.append(`File[${index}].Image`, fileItem.image.originFileObj); // `originFileObj` kullanılarak `File` nesnesine erişim
                } 
            });

            
            const response = await axios.post(
                `${apiUrl}/Meaning/add-meaning`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            
            ); 
            
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };

    const deleteMeaning = async (data:IMeaningIdRequest) => {  
        try { 
            
            const response = await axios.post(`${apiUrl}/Meaning/delete-meaning`,data);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };

    const getByIdMeaning = async (data:IMeaningIdRequest) => {  
        try { 
            
            const response = await axios.post(`${apiUrl}/Meaning/get-by-id-meaning`,data);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };
    
    const updatePublishedMeaning = async (data:any) => {  
        try { 
            
            const response = await axios.post(`${apiUrl}/Meaning/update-published-meaning`,data);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };

    const updateMeaning = async (data:IUpdateMeaning) => {  
        try { 

            const formData = new FormData(); 

            formData.append("HeadWordId", data.headWordId);
            formData.append("MeaningId", data.meaningId);
            formData.append("Order", data.order);
            formData.append("WordType", data.wordType);
            formData.append("Definition", data.definition);
            formData.append("LanguageLevel", data.languageLevel);
            formData.append("LanguageEnglish", data.languageEnglish);
            formData.append("LanguageRussian", data.languageRussian);
            formData.append("LanguageCroatian", data.languageCroatian);
            formData.append("LanguageArabic", data.languageArabic);
            
            data.deletedImages?.forEach((item) => {
                formData.append("DeletedImages", item);
            });
            data.exampleSentence.forEach((item) => {
                formData.append("ExampleSentence", item);

            });
            data.file.forEach((fileItem, index) => {
                if (fileItem.description) {
                    formData.append(`File[${index}].Description`, fileItem.description);
                }
                if (fileItem.imageId) {
                    formData.append(`File[${index}].ImageId`, fileItem.imageId);
                }
                if (fileItem.image && fileItem.image.originFileObj) {
                    formData.append(`File[${index}].Image`, fileItem.image.originFileObj); 
                } 
            });
            
            const response = await axios.post(
                `${apiUrl}/Meaning/update-meaning`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            
            ); 
            
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };




    return {
        addMeaning,
        getMeaning,
        deleteMeaning,
        getByIdMeaning,
        updateMeaning,
        updatePublishedMeaning
    };


}

export default MeaningService;
