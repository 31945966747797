import React, { useState } from 'react';
import { Button, Modal, Input, message, Upload, UploadFile, UploadProps } from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import Loading from '../../../../components/Loading';
import TematicService from '../../../../services/TematicService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

const UpdateTematicModal = (props:any) =>{


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [name, setName] = useState("");
    const [orderCode, setOrderCode] = useState("");
    const [isLoadingUpdateTematic, setIsLoadingUpdateTematic]= useState(false)
    const [isLoadingTematicList, setIsLoadingTematicList]= useState(false)
    const _tematicService = TematicService();

    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const showModal = () => {
        setIsModalOpen(true);
        getByTematicId()
    };

    const getByTematicId = async ()=>{
        try { 

            setIsLoadingTematicList(true);
            var data = {
                tematicId : props.tematicId
            }
            var response = await _tematicService.getByIdTematic(data);
            
            if (response.statusCode == 200 ) {
                console.log('response: ', response);
                setName(response.data.name)
                setOrderCode(response.data.orderCode)
                if (response.data.imgUrlName != null) { 
                    setFileList([
                        {
                            uid: '-1', // Benzersiz bir ID
                            name: response.data.imgUrlName, // Dosya adı
                            status: 'done', 
                            url: process.env.REACT_APP_FILE+response.data.imgUrl, // Dosya URL'si (görünürse)
                        },
                    ])
                }
            }
            
            response.errors.forEach((i:any) => {                
                message.error(i)
            });
        } catch (err) {
            console.log("getByTematicId :", err);
        }finally{
            setIsLoadingTematicList(false);

        }
        
    }

    const validatorTematic = (data:any)=>{

    
        if (data.name == "") {
            message.error("Tematik boş geçilemez !")
            return false;
        }
        if (data.orderCode == "") {
            message.error("Sıralama geçilemez !")
            return false;
        }
    

        return true;
        
    }

    const handleOk = async () => {
        try {

            
            let file: File | null | string = fileList.find((file) => file.originFileObj)?.originFileObj as File;
            if (file == undefined && fileList.length != 0) {
               // file = fileList[0].name
               file = new File([], fileList[0].name, { type: 'image/*' });
            }
        
            var data ={
                tematicId:props.tematicId,
                name: name,
                orderCode : orderCode,
                tematicImg : file
            }
            var result =validatorTematic(data)
            if (result == false) {
                return
            }
            setIsLoadingUpdateTematic(true);
            var response = await _tematicService.updateTematic(data);
            console.log('response: ', response);
            if (response.statusCode == 200) {
                message.success("Tematik Güncellendi")
                setIsModalOpen(false);
                setName("");
                setOrderCode("")
                setFileList([])
                // callback'i çağır
                props.onTematicUpdated();
                
                
            }
            response.errors.forEach((i:any) => {                
                message.error(i)
            });
            
        } catch (err) {
            console.log("UpdateTematicModal",err)
        }
        finally{
            setIsLoadingUpdateTematic(false);
        }
    };

    const handleCancel = () =>{

        setIsModalOpen(false)
        setName("");
        setOrderCode("")
        setFileList([])

    }

    const propsUpload: UploadProps = {
        onChange: ({ file, fileList }) => {
            setFileList(fileList); // Yeni dosya listesini güncelle
        },
        onRemove: (file) => {
            setFileList((prev) => prev.filter((item) => item.uid !== file.uid)); // Dosyayı kaldır
        },
        beforeUpload: (file) => {
            return false; // Yüklemeyi durdur
        },
        fileList,
    };


    return(
        <>
        
        <Button type="primary" 
        onClick={showModal}
        className="bg-yellow-500 hover:!bg-yellow-400">
            <FontAwesomeIcon icon={faEdit} size="1x"  />
        </Button>


        <Modal title={<>
                    <span className='font-bold'>{props.tematicName} - </span>
                    Tematik Güncelleme
                </>}
            okText="Güncelle"
            cancelText="İptal" 
            maskClosable={false} 
            okButtonProps={{ className: 'bg-yellow-500 text-white hover:!bg-yellow-300' }}  
            open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>

                <div className='mt-5'>
                    <Input className='h-[40px]' placeholder="Tematik adı" value={name} onChange={e=>{setName(e.target.value)}} />
                </div>

                <div className='mt-5'>
                    <Input className='h-[40px]' type='number' placeholder="Sıralama" value={orderCode} onChange={e=>{setOrderCode(e.target.value)}} />
                </div>

                <div className="mt-5">
                    <Upload {...propsUpload} maxCount={1} accept="image/*">
                        <Button icon={<UploadOutlined />}>Görsel Ekleyin</Button>
                    </Upload>
                </div>

                {
                    isLoadingUpdateTematic == true || isLoadingTematicList == true ? 
                    <Loading/>:<></>
                }
        </Modal>
        
        </>
    );
}

export default UpdateTematicModal;