import axios from '../interceptors/AuthInterceptor';

const DashboardService =()=> {

    const apiUrl = process.env.REACT_APP_API_URL


    const getGeneralStatistic = async () => {  
        try { 
            
            const response = await axios.get(`${apiUrl}/Dashboard/get-general-statistic`);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };
    
   
   

    return {
        getGeneralStatistic,
    };


}

export default DashboardService;
